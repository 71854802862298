import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Link from 'next/link';

import Container from './Container';

export default function BusinessesNumber({
  digital,
  writetous,
  setModalCall = false,
}) {
  return (
    <div
      className={`${
        writetous &&
        'bg-[linear-gradient(130deg,_#8603bd82_0%,_#000815_20%,_#000000_70%,_#1200b6_120%)]'
      }`}
    >
      <Container>
        <div
          className={`grid lg:grid-cols-12 ${writetous ? 'lg:gap-x-10 lg:gap-y-0 md:gap-10 gap-5 md:py-7 pb-[20px] rounded-[20px]' : `lg:gap-x-10 lg:gap-y-0 md:gap-10 gap-5`}`}
        >
          <div className="col-span-7">
            {digital?.titleEditor && (
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                className={`lg:text-left lg:leading-tight text-left font-[800] xl:text-[55px] lg:text-[48px] md:text-[32px] text-[25px] leading-[2rem] title_hd leading-tight ${writetous && 'text-[#fff]'}`}
                source={digital?.titleEditor}
              >
                {digital?.titleEditor}
              </ReactMarkdown>
            )}
            {digital?.descriptionEditor && (
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                className={`mt-[20px] xl:text-[18px] lg:text-[16px] lg:font-[400] font-[400] text-[14px] text-[#142741] ${writetous && 'text-[#fff]'}`}
                source={digital?.descriptionEditor}
              >
                {digital?.descriptionEditor}
              </ReactMarkdown>
            )}
            {digital?.btn_link?.length === 0 ? (
              <button
                onClick={() => setModalCall(digital?.btn_text)}
                className="btncolorleftright w-[fit-content] lg:inline-block mt-10 hidden border-[1px] border-[#fff] text-[#fff] py-3 lg:px-5 px-3 lg:text-[20px] text-[14px] font-[500] relative overflow-hidden"
              >
                <span className="relative z-[5] transition duration-300 ease-in-out">
                  {digital?.btn_text}
                </span>
              </button>
            ) : (
              <Link
                href={digital?.btn_link || '#'} // Ensure `href` always has a valid fallback
                className="btncolorleftright rounded-[4px] w-[fit-content] mt-10 lg:inline-block hidden border-[1px] border-[#fff] text-[#fff] py-3 lg:px-5 px-3 lg:text-[20px] text-[14px] font-[500] relative overflow-hidden"
              >
                <span className="relative z-[5] transition duration-300 ease-in-out">
                  {digital?.btn_text}
                </span>
              </Link>
            )}
          </div>
          <div
            className={` col-span-5 grid ${digital?.Reasons?.length === 6 ? 'md:grid-cols-3' : 'md:grid-cols-2'} sm:grid-cols-2 grid-cols-2 lg:gap-y-10 gap-y-6 gap-x-6 lg:text-left text-left ${digital?.btn_text && 'lg:mb-0 mb-10'}`}
          >
            {digital?.Reasons?.map(data => (
              <div key={data.text}>
                <div
                  className={`xl:text-[44px] lg:text-[34px] text-[20px] font-[800] text-[#313131] ${writetous && 'text-[#fff]'}`}
                >
                  {data?.text}
                </div>
                <div
                  className={`xl:text-[18px] lg:text-[16px] lg:font-[600] font-[400] text-[14px] text-[#142741] ${writetous && 'text-[#fff]'}`}
                >
                  {data?.description}
                </div>
              </div>
            ))}
          </div>
          <div className="text-center">
            {digital?.btn_link?.length === 0 ? (
              <button
                onClick={() => setModalCall(digital?.btn_text)}
                className="btncolorleftright w-[fit-content] lg:hidden inline-block border-[1px] border-[#fff] text-[#fff] py-3 lg:px-5 px-3 lg:text-[20px] text-[14px] font-[500] relative overflow-hidden"
              >
                <span className="relative z-[5] transition duration-300 ease-in-out">
                  {digital?.btn_text}
                </span>
              </button>
            ) : (
              <Link
                href={digital?.btn_link || '#'} // Ensure `href` always has a valid fallback
                className="btncolorleftright rounded-[4px] w-[fit-content] lg:hidden inline-block border-[1px] border-[#fff] text-[#fff] py-3 lg:px-5 px-3 lg:text-[20px] text-[14px] font-[500] relative overflow-hidden"
              >
                <span className="relative z-[5] transition duration-300 ease-in-out">
                  {digital?.btn_text}
                </span>
              </Link>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
